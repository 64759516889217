import React from 'react';
import PropTypes from 'prop-types';
import { array } from '@utils/helpers';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import Text from '@components/text';
import { Image, CustomRadio } from '@components/for-business/form/fields/styles';

const Fieldset = tw.fieldset`relative`;

const RadioBtnsWrapper = tw.div`flex flex-wrap mt-24 mb-30 md:mt-15 lg:mb-5`;

const RadioWrapper = styled.div`
  ${CustomRadio}
  ${tw`relative flex items-center w-1/3 mb-2 lg:mr-20 lg:mb-30 xl:mr-30`}
  
  @media (min-width: 1024px) { max-width: 133px; }
  
  [type="radio"] + label:after {
    content: '';
    top: 50%;
    left: 4px;
    background-color: #482525;
  }
`;

const Hidden = tw.div`hidden`;

const Input = tw.input`hidden`;

const RadioLabel = styled.label`
  ${tw`
    text-14 uppercase font-openSans font-400 leading-relaxed tracking-30 text-primary cursor-pointer
    lg:leading-snug lg:tracking-18
  `}
  ${({ lang }) => (lang === 'en' ? tw`pl-16 md:pl-22` : tw`pl-22`)}
`;

const Error = tw(Text)`absolute -bottom-5 left-23 h-30 text-error`;

const Radio = ({ lang, register, errors }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 30) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Fieldset id="radios">
      <RadioBtnsWrapper>
        <Hidden>
          <Input id="radioTitle" type="hidden" name="interested" ref={register({ required: true })} />
          <RadioLabel htmlFor="radioTitle">Interested in</RadioLabel>
        </Hidden>
        {array(6).map((number) => (
          <RadioWrapper key={number}>
            <Input
              id={number}
              type="radio"
              name="interested"
              value={intl.formatMessage({ id: `for business.form.radio.${number}` })}
              ref={register({ required: intl.formatMessage({ id: 'form.errors.radio' }) })}
            />
            <RadioLabel lang={lang} htmlFor={number}>
              <FormattedHTMLMessage id={`for business.form.radio.${number}`} />
            </RadioLabel>
            <Image fluid={data.mark.childImageSharp.fluid} />
          </RadioWrapper>
        ))}
      </RadioBtnsWrapper>
      { errors.interested && <Error fontSizes={12}>{errors.interested.message}</Error> }
    </Fieldset>
  );
};

Radio.propTypes = {
  lang: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Radio;

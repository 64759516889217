import React from 'react';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`lg:w-470 lg:mr-165`;

const Hashtag = styled(Text)`
  color: #91BCD4;
  ${tw`mb-11 lg:mb-15`}
`;

const Title = tw(Text)`mb-15`;

const Subtitle = tw(Text)`mb-10 lg:mb-20`;

const Content = tw(Text)`mb-35 lg:mr-20 lg:mb-0`;

const Information = () => (
  <Wrapper>
    <Hashtag fontSizes={[14, 16]} lineHeight={1.4} letterSpacing={[18, 20]} uppercase>
      <FormattedMessage id="for business.business.hashtag" />
    </Hashtag>
    <Title
      color="black"
      fontFamily="bebasNeue"
      fontSizes={[41, 54]}
      fontWeight={300}
      lineHeight={1.04}
      letterSpacing={[51, 67]}
    >
      <FormattedHTMLMessage id="for business.business.title" />
    </Title>
    <Subtitle fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="for business.business.content" />
    </Subtitle>
    <Content fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedHTMLMessage id="for business.business.content2" />
    </Content>
  </Wrapper>
);

export default Information;

import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Background from '@components/for-business/business/background';
import Information from '@components/for-business/business/information';
import Swipe from '@components/for-business/business/swipe';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-60 py-50 px-10 text-center
    sm:w-440
    lg:flex lg:items-end lg:w-auto lg:mb-55 lg:py-163 lg:px-0 lg:text-left
  `}
`;

const Business = () => (
  <Wrapper>
    <Background />
    <Information />
    <Swipe />
  </Wrapper>
);

export default Business;

import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { customContainer } from '@utils/twin-macro';

import Triangles from '@components/for-business/header/triangles';
import Content from '@components/for-business/header/content';
import CTA from '@components/for-business/header/cta';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`relative mb-320 pt-44 px-10 sm:mb-150 lg:mb-340 lg:pt-54 lg:pl-95 lg:pr-0`}
`;

const Image = styled(Img)`
  top: 320px;
  left: -20px;
  width: 330px;
  transform: rotate(-10deg);
  ${tw`absolute!`}
  
  @media (min-width: 640px) {
    top: 100px;
    left: 300px;
    width: 380px;
    transform: rotate(0deg);
  }
  
  @media (min-width: 768px) {
    top: -25px;
    left: 300px;
    width: 550px;
  }
  
  @media (min-width: 1024px) {
    top: -16px;
    left: 340px;
    width: 800px;
  }

  @media (min-width: 1280px) {
    top: -38px;
    left: 340px;
    width: 880px;
  }
`;

const Coffee = styled(Img)`
  top: 500px;
  left: -140px;
  width: 290px;
  ${tw`hidden lg:block lg:absolute!`}
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "for-business/header.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      image: file(relativePath: { eq: "cafe.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Triangles />
      <Content />
      <CTA />
      <Image fluid={data.header.childImageSharp.fluid} />
      <Coffee fluid={data.image.childImageSharp.fluid} />
    </Wrapper>
  );
};

export default Header;

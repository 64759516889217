import React from 'react';
import tw, { styled } from 'twin.macro';

const Triangle = tw.div`absolute w-0 h-0`;

const TriangleBlue = styled(Triangle)`
  top: 0;
  left: -40px;
  border-top: 275px solid #D7EFFD;
  border-right: 300px solid transparent;
  
  @media (min-width: 640px) {
    left: -70px;
    border-top-width: 275px;
    border-right-width: 350px;
  }

  @media (min-width: 768px) {
    left: -160px;
    border-top-width: 400px;
    border-right-width: 400px;
  }
  
  @media (min-width: 1024px) {
    left: -165px;
    border-top-width: 400px;
    border-right-width: 440px;
  }
  
  @media (min-width: 1280px) {
    left: -410px;
    border-top-width: 610px;
    border-right-width: 685px;
  }

  @media (min-width: 1921px) {
    left: -735px;
    border-top-width: 905px;
    border-right-width: 1010px;
  }
`;

const TrianglePink = styled(Triangle)`
  top: 384px;
  right: -20px;
  border-top: 300px solid #f2c1b6;
  border-left: 300px solid transparent;
  
  @media (min-width: 640px) {
    top: 200px;
    right: -70px;
    border-top-width: 350px;
    border-left-width: 350px;
  }

  @media (min-width: 768px) {
    top: 120px;
    right: -130px;
    border-top-width: 450px;
    border-left-width: 550px;
  }
  
  @media (min-width: 1024px) {
    top: 190px;
    right: -250px;
    border-top-width: 700px;
    border-left-width: 780px;
  }

  @media (min-width: 1280px) {
    right: -410px;
    border-top-width: 945px;
    border-left-width: 1040px;
  }
  
  @media (min-width: 1921px) {
    right: -730px;
    border-top-width: 1235px;
    border-left-width: 1360px;
  }
`;

const Triangles = () => (
  <>
    <TriangleBlue />
    <TrianglePink />
  </>
);

export default Triangles;

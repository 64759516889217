import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Header from '@components/for-business/header';
import Coffee from '@components/for-business/coffee';
import Business from '@components/for-business/business';
import Form from '@components/for-business/form';

const ForBusiness = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'meta tags.title.for business' })}
        description={intl.formatMessage({ id: 'meta tags.description.for business' })}
      />
      <Header />
      <Coffee lang={intl.locale} />
      <Business />
      <Form lang={intl.locale} />
    </Layout>
  );
};

export default ForBusiness;

import Img from 'gatsby-image';
import tw, { styled, css } from 'twin.macro';

export const Image = tw(Img)`absolute! top-1/2 left-0 w-12 transform -translate-y-1/2 -z-1`;

export const DefaultInput = css`
  ${tw`
    text-14 text-pink leading-relaxed tracking-18 bg-transparent border-none resize-none
    focus:outline-none
  `}

  ::placeholder {
    ${tw`text-14 leading-relaxed tracking-18 text-pink lg:text-16`}
  }
`;

export const Radio = tw.input`hidden`;

export const RadioLabel = styled.label`
  ${tw`block pl-30 font-openSans font-400 text-primary cursor-pointer`}
`;

export const CustomRadio = css`
  [type="radio"] + label:after {
    // don't use tailwind for transform in this case - problem with transition on Safari 
    transform: scale(0) translateY(-50%);
    ${tw`absolute w-5 h-5 rounded-full transition-all duration-300`}
  }

  [type="radio"]:checked + label:after {
    transform: scale(1) translateY(-50%);
  }
`;

import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Wrapper = tw.div`relative inline-block lg:mt-5`;

const Button = tw(ButtonDefault)`mt-22 py-10 px-40 bg-pink lg:mt-35`;

const CTA = () => (
  <Wrapper onClick={() => scrollTo('#form')}>
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id="navigation.contact with us short" />
      </Text>
    </Button>
  </Wrapper>
);

export default CTA;

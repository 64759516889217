import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import sendData from '@utils/forms';

import Text from '@components/text';

import Radio from '@components/for-business/form/fields/radio';
import Inputs from '@components/for-business/form/fields/inputs';
import Terms from '@components/for-business/form/fields/terms';
import Submit from '@components/for-business/form/fields/submit';
import Message from '@components/for-business/form/fields/message';

const Wrapper = tw.form`relative sm:w-320 sm:mx-auto md:w-340 md:mx-0 lg:w-480 xl:w-540`;

const Fields = ({ lang }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Wrapper
      onSubmit={handleSubmit(sendData)}
      name="business"
      method="post"
      data-netlify="true"
    >
      <input type="hidden" name="form-name" value="business" ref={register} />
      <Text fontSizes={16} lineHeight={1.5} letterSpacing={20}>
        <FormattedHTMLMessage id="form.labels.radio" />
      </Text>
      <Radio lang={lang} register={register} errors={errors} />
      <Inputs register={register} errors={errors} />
      <Terms register={register} errors={errors} />
      <Submit />
      <Message />
    </Wrapper>
  );
};

Fields.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Fields;

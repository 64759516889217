import React from 'react';
import tw, { styled } from 'twin.macro';

const Main = styled.div`
  left: -240px;
  right: -240px;
  transform: rotate(10deg);
  background-color: #D7EFFD;
  ${tw`absolute top-0 bottom-0 -z-1`}
  
  @media (min-width: 768px) {
    left: -350px;
    right: -350px;
    transform: rotate(6deg);
  }
  
  @media (min-width: 1024px) {
    top: 105px;
    bottom: 40%;
    left: -440px;
    right: -420px;
    transform: rotate(8deg);
  }
  
  @media (min-width: 1921px) {
    bottom: 28%;
    left: -780px;
    right: -780px;
  }
`;

const Desktop = styled.div`
  top: 40%;
  left: -420px;
  right: -420px;
  bottom: 0;
  background-color: #D7EFFD;
  ${tw`hidden lg:block absolute -z-1`}
  
  @media (min-width: 1921px) {
    top: 44%;
    left: -794px;
    right: -740px;
  }
`;

const Background = () => (
  <>
    <Main />
    <Desktop />
  </>
);

export default Background;

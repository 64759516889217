import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/for-business/coffee/header';
import MobileNav from '@components/for-business/coffee/mobile-nav';
import Content from '@components/for-business/coffee/content';
import DesktopNav from '@components/for-business/coffee/desktop-nav';

const types = ['office', 'horeca', 'cold brew', 'own brands', 'gifts', 'events'];

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 mb-66 px-10
    sm:w-440 sm:mx-0 sm:mb-100
    md:w-auto md:mx-auto md:mb-60
    lg:-mb-10 lg:px-0
  `}
`;

const Flex = tw.div`md:flex md:justify-between lg:lg:pl-95`;

const Coffee = ({ lang }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <Wrapper>
      <Header />
      <MobileNav
        lang={lang}
        types={types}
        activeType={types[activeItem]}
        setActiveItem={setActiveItem}
      />
      <Flex>
        <DesktopNav
          lang={lang}
          types={types}
          activeType={types[activeItem]}
          setActiveItem={setActiveItem}
        />
        <Content activeType={types[activeItem]} />
      </Flex>
    </Wrapper>
  );
};

Coffee.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Coffee;

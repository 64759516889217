import React from 'react';
import tw from 'twin.macro';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Hashtag = tw(Text)`hidden lg:block lg:relative lg:mb-7 lg:text-blue`;

const Title = tw(Text)`relative -mr-20 sm:mr-0`;

const Content = () => (
  <>
    <Hashtag fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
      <FormattedHTMLMessage id="for business.header.hashtag" />
    </Hashtag>
    <Title
      fontFamily="bebasNeue"
      fontSizes={[48, 54]}
      fontWeight={300}
      lineHeight={[1.125, 1.23]}
      letterSpacing={[130, 146]}
    >
      <FormattedHTMLMessage id="for business.header.header" />
    </Title>
  </>
);

export default Content;

import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import { disableEnter } from '@utils/helpers';

import Text from '@components/text';
import { DefaultInput } from '@components/for-business/form/fields/styles';

import NameBorder from '@images/for-business/name.svg';
import EmailBorder from '@images/for-business/e-mail.svg';
import PhoneBorder from '@images/for-business/phone.svg';
import MessagesBorder from '@images/for-business/messages.svg';

const FieldWrapper = styled.div`
  ${tw`relative mb-16`}
  
  svg { ${tw`absolute top-28 h-40 w-300 -z-1 md:w-320 lg:top-32 lg:w-480 lg:h-50 xl:w-540`} }

  &.message svg { ${tw`h-250 lg:h-300`} }
`;

const Label = tw(Text)`mb-18`;

const TextArea = styled.textarea`
  ${DefaultInput}
  ${tw`w-full pt-5 pb-35 pl-18 pr-40 bg-transparent lg:pt-14 lg:pb-45 lg:pl-36 lg:pr-56 lg:text-16`}
`;

const Input = styled.textarea`
  ${DefaultInput}
  scrollbar-width: none;
  -ms-overflow-style: none;
  ${tw`
    relative w-4/5 h-40 left-20 whitespace-nowrap overflow-x-scroll overflow-y-hidden 
    lg:left-36 lg:h-60 lg:py-10 lg:text-16
  `}

  ::-webkit-scrollbar { ${tw`w-0 h-0`} }
`;

const ErrorBorder = styled.div`svg .stroke { ${tw`stroke-error`} }`;

const Error = tw(Text)`absolute -bottom-7 left-20 text-error lg:-bottom-3 lg:left-40`;

const Inputs = ({ register, errors }) => {
  const intl = useIntl();

  return (
    <>
      <FieldWrapper>
        <Label fontSizes={14} lineHeight={1.35} letterSpacing={18}>
          <FormattedHTMLMessage id="form.labels.name" />
        </Label>
        <Input
          type="text"
          name="name"
          onKeyDown={disableEnter}
          placeholder={intl.formatMessage({ id: 'form.placeholders.name' })}
          ref={register({ required: intl.formatMessage({ id: 'form.errors.required' }) })}
        />
        { errors.name ? <ErrorBorder><NameBorder /></ErrorBorder> : <NameBorder /> }
        { errors.name && <Error fontSizes={12}>{errors.name.message}</Error> }
      </FieldWrapper>
      <FieldWrapper>
        <Label fontSizes={14} lineHeight={1.35} letterSpacing={18}>
          <FormattedHTMLMessage id="form.labels.e-mail" />
        </Label>
        <Input
          type="email"
          name="email"
          onKeyDown={disableEnter}
          placeholder={intl.formatMessage({ id: 'form.placeholders.e-mail' })}
          ref={register({
            required: intl.formatMessage({ id: 'form.errors.required' }),
            pattern: {
              value: /^\S+@\S+$/i,
              message: intl.formatMessage({ id: 'form.errors.e-mail' }),
            },
          })}
        />
        { errors.email ? <ErrorBorder><EmailBorder /></ErrorBorder> : <EmailBorder /> }
        { errors.email && <Error fontSizes={12}>{errors.email.message}</Error> }
      </FieldWrapper>
      <FieldWrapper>
        <Label fontSizes={14} lineHeight={1.35} letterSpacing={18}>
          <FormattedHTMLMessage id="form.labels.phone" />
        </Label>
        <Input
          placeholder={intl.formatMessage({ id: 'form.placeholders.phone' })}
          type="phone"
          name="phone"
          onKeyDown={disableEnter}
          ref={register({ required: intl.formatMessage({ id: 'form.errors.required' }) })}
        />
        { errors.phone ? <ErrorBorder><PhoneBorder /></ErrorBorder> : <PhoneBorder /> }
        { errors.phone && <Error fontSizes={12}>{errors.phone.message}</Error> }
      </FieldWrapper>
      <FieldWrapper className="message">
        <Label fontSizes={14} lineHeight={1.35} letterSpacing={18}>
          <FormattedHTMLMessage id="form.labels.message" />
        </Label>
        <TextArea
          placeholder={intl.formatMessage({ id: 'form.placeholders.message business' })}
          type="text"
          name="message"
          rows="9"
          ref={register}
        />
        <MessagesBorder />
      </FieldWrapper>
    </>
  );
};

Inputs.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Inputs;

import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`hidden text-center md:block`;

const Subheader = tw(Text)`mb-50 lg:mt-8 lg:mb-60`;

const Header = () => (
  <Wrapper>
    <Text
      color="black"
      fontFamily="bebasNeue"
      fontSizes={[40, 54]}
      fontWeight={300}
      lineHeight={1.15}
      letterSpacing={67}
    >
      <FormattedHTMLMessage id="for business.coffee.header" />
    </Text>
    <Subheader fontSizes={16} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="for business.coffee.subheader" />
    </Subheader>
  </Wrapper>
);

export default Header;

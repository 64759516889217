import React, { useState } from 'react';
import tw, { styled, css } from 'twin.macro';
import { testimonials } from '@utils/variables';
import { graphql, useStaticQuery } from 'gatsby';

import Img from 'gatsby-image';
import SwipeableViews from 'react-swipeable-views';
import Opinion from '@components/for-business/business/opinion';

const Wrapper = styled.div`
  top: 40px;
  ${tw`lg:relative lg:w-418`}
`;

const SwipeableViewsWrapper = tw.div`relative transition-all duration-400`;

const Slide = tw.div`flex flex-col justify-center h-full lg:justify-end`;

const Image = styled.div`
  bottom: 20px;
  right: 0;
  width: 117px;
  transition: visibility 0s linear 0s, opacity 300ms;
  ${tw`hidden opacity-100 visible lg:block lg:absolute! cursor-pointer`}
  ${({ hidden }) => hidden && css`
    transition: visibility 0s linear 300ms, opacity 300ms;
    ${tw`opacity-0 invisible`}
  `}
  
  @media (min-width: 1280px) { right: -60px; }
`;

const Pagination = tw.div`flex justify-center items-center mt-35`;

const DotWrapper = tw.div`flex justify-center items-center w-20 h-12`;

const Dot = styled.div`
  ${tw`w-10 h-10 bg-brownDot opacity-50 rounded-full transition-all duration-300 cursor-pointer`}
  ${({ active }) => active && tw`opacity-100`}
`;

const Swipe = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "for-business/business.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [activeTestimonial, handleTestimonial] = useState(0);

  return (
    <>
      <Wrapper>
        <SwipeableViewsWrapper firstSlide={activeTestimonial === 0}>
          <SwipeableViews
            index={activeTestimonial}
            onChangeIndex={handleTestimonial}
          >
            {testimonials.map((testimonial, index) => (
              <Slide
                key={testimonial.name}
                onClick={() => handleTestimonial(index)}
              >
                <Opinion testimonial={testimonial} />
              </Slide>
            ))}
          </SwipeableViews>
        </SwipeableViewsWrapper>
        <Pagination>
          {testimonials.map((testimonial, index) => (
            <DotWrapper
              key={`${testimonial.name}id`}
              onClick={() => index !== activeTestimonial && handleTestimonial(index)}
            >
              <Dot active={index === activeTestimonial} />
            </DotWrapper>
          ))}
        </Pagination>
      </Wrapper>
      <Image
        hidden={testimonials.length <= activeTestimonial + 1}
        onClick={() => testimonials.length > activeTestimonial + 1
          && handleTestimonial(activeTestimonial + 1)}
      >
        <Img fluid={data.image.childImageSharp.fluid} />
      </Image>
    </>
  );
};

export default Swipe;

import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

import Text from '@components/text';
import {
  Radio, RadioLabel, Image, CustomRadio,
} from '@components/for-business/form/fields/styles';

const Hidden = tw.div`hidden`;

const Wrapper = styled.div`
  ${CustomRadio}
  ${tw`relative flex items-center relative mb-30 lg:mt-0 lg:mb-0`}
  
  [type="radio"] + label:after {
    content: '';
    top: 10px;
    left: 4px;
    background-color: #482525;
  }

  ${Image} { top: 10px; }
`;

const Error = tw(Text)`absolute -bottom-20 left-30 text-error`;

const Terms = ({ register, errors }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      mark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <fieldset id="termsAccepted">
        <Hidden>
          <Radio id="title" type="hidden" name="terms" ref={register({ required: true })} />
          <RadioLabel htmlFor="title">Terms Accepted</RadioLabel>
        </Hidden>
        <Radio
          id="terms"
          type="radio"
          name="terms"
          value="accepted"
          ref={register({ required: intl.formatMessage({ id: 'form.errors.terms' }) })}
        />
        <RadioLabel htmlFor="terms">
          <Text fontSizes={14} lineHeight={1.36} letterSpacing={18}>
            <FormattedHTMLMessage id="form.terms" />
          </Text>
        </RadioLabel>
        <Image fluid={data.mark.childImageSharp.fluid} />
        { errors.terms && <Error fontSizes={12}>{errors.terms.message}</Error> }
      </fieldset>
    </Wrapper>
  );
};

Terms.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Terms;

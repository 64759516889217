import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import MarkDefault from '@images/mark.svg';
import Text from '@components/text';

const Wrapper = tw.div`relative mb-20 md:hidden`;

const ActiveItem = tw.div`flex justify-between items-center`;

const Relative = tw.div`relative mr-20`;

const Title = styled(Text)`
  ${tw`flex items-center h-74 -mb-8`}
  
  b {
    letter-spacing: ${({ lang }) => (lang === 'en' ? -1.5 : 0)}px;
    ${({ lang, block }) => (lang === 'en' && block && tw`first:block`)}
  }
`;

const Subtitle = tw(Text)`-mr-10`;

const Mark = styled(MarkDefault)`
  top: -28px;
  left: -10px;
  transform: rotate(-7deg) scaleX(-1);
  ${tw`absolute w-280 h-120 -z-1`}
  
  .color { fill: #FAF3EB; }
`;

const Triangle = styled.div`
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #000;
  transform: scaleY(1);
  ${tw`w-0 h-0 mr-5 transition-transform duration-400`}

  ${Wrapper}.active & { transform: scaleY(-1); }
`;

const Nav = styled.div`
  ${tw`absolute h-0 w-320 -ml-10 text-center bg-white transition-all duration-400 overflow-hidden sm:w-440`}
  
  ${Wrapper}.active & { ${tw`h-460 sm:h-370`} }
`;

const NavItem = tw.div`py-12 first:pt-18 border-pink-300 border-b`;

const NavTitle = styled(Text)`
  b { letter-spacing: ${({ lang }) => (lang === 'en' ? -0.7 : 0)}px; }
`;

const MobileNav = ({
  types, activeType, lang, setActiveItem,
}) => {
  const [isMenuOpen, handleMenu] = useState(false);

  const setItem = (val) => {
    setActiveItem(val);
    handleMenu(false);
  };

  return (
    <Wrapper className={isMenuOpen ? 'active' : ''}>
      <ActiveItem onClick={() => handleMenu(!isMenuOpen)}>
        <Relative>
          <Title lang={lang} block={activeType !== 'horeca'} fontFamily="bebasNeue" fontSizes={42} fontWeight={300} lineHeight={0.9} letterSpacing={1}>
            <FormattedHTMLMessage id={`for business.coffee.types.${activeType}.header`} />
          </Title>
          <Subtitle fontSizes={16} lineHeight={1.4} letterSpacing={38}>
            <FormattedMessage id={`for business.coffee.types.${activeType}.subheader`} />
          </Subtitle>
          <Mark />
        </Relative>
        <Triangle />
      </ActiveItem>
      <Nav>
        {types.map((type, index) => (
          <NavItem key={type} onClick={() => setItem(index)}>
            <NavTitle lang={lang} fontSizes={22}>
              <FormattedHTMLMessage id={`for business.coffee.types.${type}.header`} />
            </NavTitle>
            <Text fontSizes={14}>
              <FormattedMessage id={`for business.coffee.types.${type}.subheader`} />
            </Text>
          </NavItem>
        ))}
      </Nav>
    </Wrapper>
  );
};

MobileNav.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeType: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  setActiveItem: PropTypes.func.isRequired,
};

export default MobileNav;

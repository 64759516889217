import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';

import Header from '@components/for-business/form/header';
import Fields from '@components/for-business/form/fields';

const Wrapper = styled.div`
  ${customContainer}
  ${tw`
    relative w-320 -mb-15 pt-20 px-10
    sm:w-440
    md:flex md:justify-between md:w-auto md:mb-0
    lg:w-auto lg:pt-40 lg:px-0
  `}
`;

const Form = ({ lang }) => (
  <Wrapper id="form">
    <Header />
    <Fields lang={lang} />
  </Wrapper>
);

Form.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Form;

import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Wrapper = tw.div`h-433 sm:h-330 md:flex-1 md:h-auto md:ml-50 lg:flex-initial lg:w-535 lg:ml-0`;

const Title = styled(Text)`
  ${tw`mb-36 pr-5 lg:mb-30 lg:pr-0`}
  
  b { ${tw`font-600`} } 
`;

const ScrollTo = tw.div`mt-28 lg:mt-15`;

const Button = tw(ButtonDefault)`mt-0 py-10 px-28`;

const Content = ({ activeType }) => (
  <Wrapper>
    <Title
      fontSizes={[16, 24]}
      fontWeight={[400, 300]}
      lineHeight={[1.75, 1.43]}
      letterSpacing={[41, 72]}
    >
      <FormattedHTMLMessage id={`for business.coffee.types.${activeType}.content`} />
    </Title>
    <Text fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
      <FormattedMessage id="for business.coffee.contact" />
    </Text>
    <ScrollTo onClick={() => scrollTo('#form')}>
      <Button>
        <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
          <FormattedMessage id="for business.coffee.btn" />
        </Text>
      </Button>
    </ScrollTo>
  </Wrapper>
);

Content.propTypes = {
  activeType: PropTypes.string.isRequired,
};

export default Content;

import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import MarkDeafult from '@images/mark-big.svg';
import Text from '@components/text';

const Wrapper = tw.div`hidden md:block md:flex md:flex-col md:w-310 lg:mt-10`;

const Type = styled.div`
  ${tw`relative cursor-pointer`}
  
  :nth-of-type(2),
  :nth-of-type(3),
  :nth-of-type(6) {
    svg {
      top: -32px;
      left: -50px;
      width: 320px;
    }
  }
  
  &.active { ${tw`mb-35`} }
`;

const Title = styled(Text)`
  ${Type}.active & {
    line-height: 1;
    ${tw`font-bebasNeue -mb-10 text-42 font-300 tracking-1`}

    b { ${({ lang }) => lang === 'en' && tw`first:block`} } 
  }
`;

const Mark = styled(MarkDeafult)`
  top: -20px;
  left: -80px;
  transform: rotate(2deg);
  ${tw`hidden absolute w-305 -z-1 lg:transform-none`}
  
  ${Type}.active & { ${tw`block`} }
  
  .color { fill: #FAF3EB; }
`;

const TypeDesc = styled(Text)`
  ${tw`mt-6 mb-25 opacity-70`}
  
  ${Type}.active & {
    line-height: 2.4;
    ${tw`m-0 opacity-100`}
  }
`;

const DesktopNav = ({
  types, activeType, lang, setActiveItem,
}) => (
  <Wrapper>
    {types.map((type, index) => (
      <Type
        key={type}
        onClick={() => setActiveItem(index)}
        className={activeType === type ? 'active' : ''}
      >
        <Title lang={lang} fontSizes={18} fontWeight={600} lineHeight={1.3} letterSpacing={43}>
          <FormattedHTMLMessage id={`for business.coffee.types.${type}.header`} />
        </Title>
        <TypeDesc fontSizes={16} lineHeight={1.4} letterSpacing={38}>
          <FormattedMessage id={`for business.coffee.types.${type}.subheader`} />
        </TypeDesc>
        <Mark />
      </Type>
    ))}
  </Wrapper>
);

DesktopNav.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeType: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  setActiveItem: PropTypes.func.isRequired,
};

export default DesktopNav;

import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Img from 'gatsby-image';
import Text from '@components/text';

const MainText = tw(Text)`mb-25`;

const Flex = tw.div`flex items-center text-left`;

const Person = tw(Text)`mb-6`;

const PersonImgWrapper = tw.div`flex justify-center items-center w-75 h-75 mr-20`;

const PersonImg = styled(Img)`
  ${tw`w-full`}
  ${({ rounded }) => rounded && tw`rounded-full`}
`;

const PersonPosition = styled(Text)`
  color: #8BBEDC;
  ${tw`mr-40 sm:mr-0`}
`;

const Opinion = ({ testimonial }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "for-business/business.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      weWork: file(relativePath: { eq: "testimonials/wework.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coffeeDesk: file(relativePath: { eq: "testimonials/coffeedesk.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      konesso: file(relativePath: { eq: "testimonials/konesso.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      horeca: file(relativePath: { eq: "testimonials/horeca.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      primulator: file(relativePath: { eq: "testimonials/primulator.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      unroasted: file(relativePath: { eq: "testimonials/unroasted.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <MainText fontSizes={18} lineHeight={1.78} letterSpacing={0} italic>
        <FormattedMessage id={`testimonials.${testimonial.name}.text`} />
      </MainText>
      <Flex>
        <PersonImgWrapper>
          <PersonImg
            rounded={testimonial.rounded}
            fluid={data[testimonial.name].childImageSharp.fluid}
          />
        </PersonImgWrapper>
        <div>
          <Person fontSizes={18} fontWeight={700} lineHeight={1.3} letterSpacing={22}>
            <FormattedMessage id={`testimonials.${testimonial.name}.person`} />
          </Person>
          <PersonPosition fontSizes={18} lineHeight={1.3} letterSpacing={22} underline>
            <FormattedMessage id={`testimonials.${testimonial.name}.cta`} />
          </PersonPosition>
        </div>
      </Flex>
    </>
  );
};

Opinion.propTypes = {
  testimonial: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  ).isRequired,
};

export default Opinion;

import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';

const Button = tw(ButtonDefault)`mt-5 py-10 px-30 sm:mb-40 lg:mt-35 lg:mb-25`;

const Submit = () => (
  <Button type="submit">
    <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
      <FormattedMessage id="form.submit business" />
    </Text>
  </Button>
);

export default Submit;

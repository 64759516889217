import React from 'react';
import Img from 'gatsby-image';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`md:w-350 lg:w-auto`;

const Subtitle = tw(Text)`mt-15 mb-400 sm:mb-440 md:mb-0`;

const Image = styled(Img)`
  top: 120px;
  left: -16px;
  width: 400px;
  transform: scaleX(-1);
  ${tw`absolute!`}

  @media (min-width: 768px) {
    width: 460px;
    left: -50px;
    transform: scaleX(1);
  }
  
  @media (min-width: 1024px) {
    top: 95px;
    left: -225px;
    width: 723px;
  }
`;

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "for-business/form.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Text
        color="black"
        fontFamily="bebasNeue"
        fontSizes={[42, 54]}
        fontWeight={300}
        lineHeight={[1, 1.14]}
        letterSpacing={[52, 67]}
      >
        <FormattedHTMLMessage id="for business.form.header" />
      </Text>
      <Subtitle fontSizes={16} fontWeight={600} lineHeight={1.5} letterSpacing={20}>
        <FormattedMessage id="for business.form.offer" />
      </Subtitle>
      <Image fluid={data.image.childImageSharp.fluid} />
    </Wrapper>
  );
};

export default Header;
